






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: "Quản lý thị trường",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
              options: {
                to(_, item) {
                  return `/app/markets/${item._id}/`;
                },
                attrs: {
                  class: "text-no-wrap",
                },
              },
            },
            country: {
              text: "Quốc gia",
              options: {
                subProp: "country.name",
                label: true,
                sortBy: "countryId",
              },
            },
            code: {
              text: "Mã",
              sortable: true,
            },
            currency: {
              text: "Đơn vị tiền tệ",
              sortable: true,
            },
            mktDomainApplied: {
              text: "Áp dụng MKT Domain",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            tmdt: {
              text: "TMDT",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            rd: {
              text: "RD",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            main: {
              text: "TT Chính",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            wholesale: {
              text: "Khách sỉ",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            countryCode: {
              text: "Country Code",
              sortable: true,
            },
            exchangeRate: userManager.checkRole(["kt", "qlk"]) && {
              text: "Tỷ giá",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            mktPlatforms: {
              text: "Nền tảng MKT",
              options: {
                labels(items) {
                  return items && items.map((item) => item.name);
                },
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            syncOrders: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Đồng bộ đơn hàng",
                icon: "mdi-sync",
              },
              on: {
                async xClick({ self }) {
                  const dataTable = self.context();
                  self.loading.value = true;
                  await dataTable.execute(
                    async () => {
                      return await coreApiClient.call("app", "syncOrders");
                    },
                    undefined,
                    {
                      disableLoading: true,
                      willRefresh: true,
                    }
                  );
                  self.loading.value = false;
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              presetExchangeRate: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật tỷ giá",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật tỉ giá",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextExchangeRate: item.nextExchangeRate,
                            nextExchangeRateStartTime: item.nextExchangeRateStartTime,
                          });
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextExchangeRate: {
                                      type: "number",
                                      attrs: {
                                        label: "Tỷ giá mới",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: `VNĐ / ${item.currency}`,
                                        autofocus: true,
                                      },
                                    },
                                    nextExchangeRateStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển tỉ giá",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "markets",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextExchangeRate: data.nextExchangeRate,
                                        nextExchangeRateStartTime: data.nextExchangeRateStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("markets", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("markets", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "markets",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("markets", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              countryId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Quốc gia",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("countries", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              code: {
                attrs: {
                  label: "Mã",
                  required: true,
                },
              },
              currency: {
                attrs: {
                  label: "Đơn vị tiền tệ",
                  required: true,
                },
              },
              exchangeRate: {
                attrs: {
                  label: "Tỉ giá",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              mktDomainApplied: {
                type: "boolean",
                attrs: {
                  label: "Áp dụng MKT Domain",
                },
              },
              tmdt: {
                type: "boolean",
                attrs: {
                  label: "TMDT",
                },
              },
              rd: {
                type: "boolean",
                attrs: {
                  label: "RD",
                },
              },
              main: {
                type: "boolean",
                attrs: {
                  label: "TT Chính",
                },
              },
              wholesale: userManager.checkRole(["kt", "vd", "vdl"]) && {
                type: "boolean",
                attrs: {
                  label: "Khách sỉ",
                },
              },
              countryCode: {
                attrs: {
                  label: "Country Code",
                },
              },
              mktPlatformIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: [
                  "name",
                  "countryId",
                  "code",
                  "currency",
                  "exchangeRate",
                  "description",
                  "mktDomainApplied",
                  "tmdt",
                  "rd",
                  "main",
                  "wholesale",
                  "countryCode",
                  "mktPlatformIds",
                ],
              },
            },
            editForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: [
                  "name",
                  "countryId",
                  "code",
                  "currency",
                  "exchangeRate",
                  "description",
                  "mktDomainApplied",
                  "tmdt",
                  "rd",
                  "main",
                  "wholesale",
                  "countryCode",
                  "mktPlatformIds",
                ],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
